import {
  fileEmpty,
  fileExcel,
  fileOpenOffice,
  filePdf,
  fileWord,
  fileZip,
} from '@xxxlgroup/hydra-icons';
import { checkType } from 'utils/files';

const fileIconsMapping = {
  other: fileEmpty,
  image: fileEmpty,
  excel: fileExcel,
  openoffice: fileOpenOffice,
  pdf: filePdf,
  word: fileWord,
  zip: fileZip,
};

export const prepareFile = (file) =>
  new Promise((resolve) => {
    if (file) {
      resolve({
        icon: fileIconsMapping[checkType(file.type)] || fileEmpty,
        name: file.name,
      });
    }
  });

export const prepareImage = (file) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      if (event.target.result) {
        resolve({
          image: event.target.result,
          type: 'preview',
          name: file.name,
        });
      }
    };

    reader.readAsDataURL(file);
  });

export const rawFilesToPreviews = (files) =>
  files
    .filter(({ raw }) => raw)
    .map(({ raw }) =>
      checkType(raw.type) === 'image' ? prepareImage(raw) : prepareFile(raw),
    );
