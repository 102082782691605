import React from 'react';
import classnames from 'classnames';
import { pseudoIcon } from '@xxxlgroup/hydra-utils/icon';
import { Glyph } from 'types/typeDefinitions';

import styles from 'molecules/ProductCard/components/ProductCardVariant/ProductCardVariant.scss';

interface ProductCardVariantProps {
  /** @ignore */
  className?: string;
  /** optional icon for the variant */
  glyph?: Glyph;
  /** messages for variants */
  i18n: {
    variantMobile: string;
    variantDesktop: string;
  };
}

const ProductCardVariant = ({
  className,
  glyph,
  i18n,
}: ProductCardVariantProps) => {
  if (!glyph) {
    return null;
  }

  const [iconStyle, iconClassName] = pseudoIcon(glyph);
  const isVariantTextEqual = i18n.variantDesktop === i18n.variantMobile;

  if (isVariantTextEqual) {
    return (
      <span
        style={iconStyle}
        className={classnames(styles.term, className, iconClassName)}
      >
        {i18n.variantMobile}
      </span>
    );
  }

  return (
    <>
      <span
        style={iconStyle}
        className={classnames(styles.shortTerm, className, iconClassName)}
      >
        {i18n.variantMobile}
      </span>
      <span
        style={iconStyle}
        className={classnames(styles.longTerm, className, iconClassName)}
      >
        {i18n.variantDesktop}
      </span>
    </>
  );
};

export default ProductCardVariant;
