import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { getPriceParts } from 'molecules/PriceCompound/utils/priceFormat';
import { getUnitParts } from 'molecules/PriceCompound/utils/unit';
import {
  CurrencyFormat,
  PriceSizes,
} from 'molecules/PriceCompound/PriceCompound.types';
import { usePriceContext } from 'molecules/PriceCompound/PriceCompound.context';
import FractionSuperscript from 'molecules/PriceCompound/components/FractionSuperscript';
import styles from 'molecules/PriceCompound/components/PricePerUnit/PricePerUnit.scss';

interface PricePerUnitProps {
  /** The currency of the price */
  currencyFormat?: CurrencyFormat;
  className?: string;
  /** if the product unit is package, this prop is needed */
  packageData?: { name?: string; productPrice?: number; unitAmount?: number };
  /** The unit e.g. litre, square meter, etc. */
  unit: string;
  /** Shows the price per unit */
  unitPrice?: number;
  /** The size of the price. "small" is a reduced version */
  size?: PriceSizes;
}

const formattedPackageAmount = (
  unitAmountInPackage: number,
  formattedUnit: React.ReactNode,
) => {
  const packageUnit = unitAmountInPackage.toString().includes('.')
    ? unitAmountInPackage.toString().replace('.', ',')
    : unitAmountInPackage;

  return (
    <>
      {` (= ${packageUnit} `}
      {formattedUnit})
    </>
  );
};

const PricePerUnit = ({
  currencyFormat: currencyFormatProp = {},
  className,
  packageData = {},
  unit,
  unitPrice,
  size: sizeProp = PriceSizes.LARGE,
  ...other
}: PricePerUnitProps) => {
  const { currencyFormat = currencyFormatProp, size = sizeProp } =
    usePriceContext();

  const { base, power } = getUnitParts(unit);

  const renderFormattedPrice = useCallback(
    (value?: number) => {
      const { integer, decimal, fraction, symbol } = getPriceParts(
        value,
        currencyFormat,
      );

      return (
        <>
          {`${integer}${decimal}`}
          {size === PriceSizes.LARGE ? (
            <FractionSuperscript
              value={fraction}
              className={styles.fraction}
              currencySymbol={symbol}
            />
          ) : (
            `${fraction}`
          )}
        </>
      );
    },
    [currencyFormat, size],
  );

  const formattedUnit = useMemo(
    () => (
      <>
        {base}
        {power && <sup>{power}</sup>}
      </>
    ),
    [base, power],
  );

  const renderPricePerUnit = (
    <>
      {renderFormattedPrice(unitPrice)} / {formattedUnit}
    </>
  );

  const {
    name: packageName,
    productPrice,
    unitAmount: unitAmountInPackage,
  } = packageData;

  const renderPricePerPackage = useMemo(
    () =>
      unitAmountInPackage ? (
        <>
          {renderFormattedPrice((productPrice ?? 0) * unitAmountInPackage)}
          {` / `}
          {packageName}
          {size === PriceSizes.LARGE &&
            formattedPackageAmount(unitAmountInPackage, formattedUnit)}
        </>
      ) : null,
    [
      unitAmountInPackage,
      renderFormattedPrice,
      productPrice,
      packageName,
      size,
      formattedUnit,
    ],
  );

  return (
    <div
      className={classNames(styles.pricePerUnit, styles[size], className)}
      {...other}
    >
      {packageData.name ? renderPricePerPackage : renderPricePerUnit}
    </div>
  );
};

export default PricePerUnit;
