import React from 'react';
import Heading from 'components/Heading';
import { SEOLevel } from 'types/typeDefinitions';

interface CardHeadingProps {
  content?: string;
  className?: string;
  SEO?: boolean | SEOLevel;
}

const CardHeading = ({ content, className, SEO = false }: CardHeadingProps) => (
  <Heading level={3} SEO={SEO} content={content} className={className} />
);

export default CardHeading;
