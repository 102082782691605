import React, { cloneElement } from 'react';
import classnames from 'classnames';

import { CardProps } from 'molecules/Card/Card.types';
import CardHeading from 'molecules/Card/components/CardHeading';
import Content from 'components/Content';

import styles from 'molecules/Card/Card.scss';

const Card = ({
  action,
  alignment = 'center',
  children,
  className,
  content,
  contentClassName,
  headingClassName,
  hasBorder = false,
  link,
  overlay,
  renderLink,
  SEO,
  target = '_self',
  title,
  theme = 'default',
  variant = 'text',
  ...other
}: CardProps) => {
  const cardAttributes = {
    className: classnames(
      styles.card,
      className,
      styles[`align-${alignment}`],
      { [styles.nolink]: !link && !renderLink },
      styles[`theme-${theme}`],
      styles[`variant-${variant}`],
      { [styles.border]: hasBorder },
    ),
    ...other,
  };

  const Tag = title && SEO ? 'article' : 'div';

  const cardHeading = (
    <CardHeading
      key="card-heading"
      content={title}
      SEO={SEO}
      className={headingClassName}
    />
  );

  const renderTitle = () => {
    if (renderLink) {
      return <>{renderLink({ className: styles.link, target }, cardHeading)}</>;
    }

    // Please remove with https://jira.xxxlgroup.com/browse/DISCO-276
    /* istanbul ignore next */
    if (link) {
      return cloneElement(link, { target, className: styles.link }, [
        cardHeading,
      ]);
    }

    return title && cardHeading;
  };

  const renderOverlay = () => {
    if (!overlay) {
      return null;
    }

    if (renderLink) {
      return <>{renderLink({ className: styles.link, target }, overlay)}</>;
    }

    // Please remove with https://jira.xxxlgroup.com/browse/DISCO-276
    /* istanbul ignore next */
    if (link) {
      return cloneElement(link, { target, className: styles.link }, [overlay]);
    }

    return overlay;
  };

  return (
    <div {...cardAttributes}>
      <Tag className={styles.wrapper}>
        {!overlay && renderTitle()}
        {content && (
          <Content
            content={content}
            className={classnames(styles.content, { contentClassName })}
          />
        )}
        {action}
        {children}
        {renderOverlay()}
      </Tag>
    </div>
  );
};

export default Card;
