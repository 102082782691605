import React from 'react';
import { noop } from '@xxxlgroup/hydra-utils/common';
import { check, trashCan } from '@xxxlgroup/hydra-icons';
import Icon from 'components/Icon';
import IconButton from 'components/IconButton';
import Image from 'components/Image';
import { FileI18nType, PreviewType } from 'molecules/Dropzone/Dropzone.types';

import styles from 'molecules/Dropzone/components/File/File.scss';

interface FileProps {
  /** a callback function, which provides the file index as a parameter and should return markup with additional action bar */
  actionBar?: (index: number) => React.ReactNode;
  disabled: boolean;
  /** i18n */
  i18n?: FileI18nType;
  /** the indexed position of the file in the previews array */
  index: number;
  /** callback for file removing */
  onRemove?: (event: React.MouseEvent, indexToRemove: number) => void;
  /** callback for replacing uploaded file */
  onFileReplace?: (indexToReplace: number) => void;
  /** function to open the file dialog */
  open?: () => void;
  /** the data needed for a file preview */
  preview: PreviewType;
}

const File = ({
  actionBar = undefined,
  disabled = false,
  preview,
  index,
  open = noop,
  onRemove = noop,
  onFileReplace = noop,
  i18n = { removeLabel: undefined, successLabel: undefined },
}: FileProps) => {
  const { name, image, icon } = preview;
  const showRemoveButton = onRemove !== noop;
  const showExternalActionBar = actionBar !== undefined;
  const showActions = showExternalActionBar || showRemoveButton;

  const handleRemove = (event: React.MouseEvent) => onRemove(event, index);

  const media = image ? (
    <Image
      altText={name}
      className={styles.image}
      data-purpose="filespreview.image"
      source={image}
    />
  ) : (
    <Icon
      className={styles.iconFile}
      data-purpose="filespreview.svg"
      glyph={icon}
    />
  );

  const openFileDialog = (
    event:
      | React.MouseEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLInputElement>,
  ) => {
    event.stopPropagation();
    if (disabled) {
      return;
    }
    open();
    onFileReplace(index);
  };

  const handleKeypress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = event;
    if (['Enter', 'Space'].includes(key)) {
      openFileDialog(event);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.media}>{media}</div>
      <div className={styles.wrapperName}>
        <input
          className={styles.input}
          onKeyPress={handleKeypress}
          onClick={openFileDialog}
          type="text"
          autoComplete="off"
          readOnly
          placeholder={name}
        />
        {i18n?.successLabel && (
          <span className={styles.wrapperSuccessIcon}>
            <Icon
              className={styles.iconSuccess}
              glyph={check}
              ariaLabel={i18n.successLabel}
            />
          </span>
        )}
      </div>
      {showActions && (
        <div className={styles.actionBar}>
          {showExternalActionBar && <div>{actionBar?.(index)}</div>}
          {showRemoveButton && (
            <IconButton
              ariaLabel={i18n?.removeLabel}
              className={styles.iconDelete}
              data-purpose="dropzone.button.remove"
              glyph={trashCan}
              onClick={handleRemove}
              type="button"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default File;
