import React from 'react';

/**
 * Defines the format for the desired currency used
 */
export interface CurrencyFormat {
  /** The locale to be used in Intl.numberformat */
  locale?: string;
  /** The options passed to Intl.numberformat */
  options?: {
    /** The style of the format ( decimal, currency, percent ) */
    style: 'currency' | 'decimal' | 'percent';
    /** The Currency to use if style is currency */
    currency: string;
  };
  /** The Custom formats to be done after numberformat */
  custom?: {
    postfix: string;
    noEmptySpaceBeforePostfix: boolean;
  };
  /** A flag if the currency symbol should be shown */
  hideCurrency?: boolean;
  /** A flag if `.00` should be shown as '.‒' */
  zeroFractionWithDash?: boolean;
}

export const FRACTION_ZERO_DASH = '‒'; // dash ('‒'), not minus!

export enum PriceSizes {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export interface PriceOldProps {
  /** Show the number asterisks of the price */
  asteriskCount?: number;
  /** @ignore */
  className?: string;
  /** The format options for the current currency */
  currencyFormat?: CurrencyFormat;
  /** @deprecated - add a string into the value prop */
  insteadOf?: string;
  /** The old price without the discount */
  value: React.ReactNode | number;
  /** The size of the price. "small" is a reduced version */
  size?: PriceSizes;
  /** indicates if the old price is strike through or not */
  strikeThrough?: boolean;
}

export interface PriceCurrentProps {
  /** The id of an element to make references on the element possible */
  id?: string;
  className?: string;
  /** The format options for the current currency */
  currencyFormat?: CurrencyFormat;
  /** The old price without the discount */
  discounted?: boolean;
  /** Introduce a plus symbol before the price */
  hasPlusSign?: boolean;
  /** The unit e.g. litre, square meter, etc. */
  unit?: string;
  /** The current price */
  value: number;
  /** The size of the price. "small" is a reduced version */
  size: PriceSizes;
}
