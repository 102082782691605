import React from 'react';
import classnames from 'classnames';
import Heading from 'components/Heading';
import { splitFirstWords } from '@xxxlgroup/hydra-utils/common';
import styles from 'molecules/ProductCard/components/ProductCardTitle/ProductCardTitle.scss';

interface ProductCardTitleProps {
  /** @ignore */
  className?: string;
  /** mandatory title of product card */
  title: string;
}

const ProductCardTitle = ({ className, title }: ProductCardTitleProps) => {
  const [firstWord, remainingWords] = splitFirstWords(title);
  const trimContent = firstWord?.trim();
  const remainingContent = remainingWords || '';

  const titleWithWordsBold = `<span class=${styles.firstWord}>${trimContent}</span> ${remainingContent}`;

  return (
    <Heading
      className={classnames(styles.name, className)}
      content={titleWithWordsBold}
      level={3}
      SEO
    />
  );
};

export default ProductCardTitle;
