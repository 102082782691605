import React, { forwardRef, useState, cloneElement } from 'react';
import classnames from 'classnames';
import { config } from '@xxxlgroup/hydra-config';

import { ProductCardProps } from 'molecules/ProductCard/ProductCard.types';
import ProductCardTitle from 'molecules/ProductCard/components/ProductCardTitle';
import Image from 'components/Image';

import styles from 'molecules/ProductCard/ProductCard.scss';

const ProductCard = forwardRef<HTMLElement, ProductCardProps>(
  (
    {
      additionalContent,
      children,
      density,
      imageSizes,
      title,
      subTitle,
      imageData,
      isDense = false,
      hoverImageData,
      labels,
      link,
      className,
      variants,
      variantsClassName,
      ...other
    },
    ref,
  ) => {
    const [variantPreviewIndex, setVariantPreviewIndex] = useState(-1);

    const variantMouseEnter = (index: number) => () => {
      setVariantPreviewIndex(index);
    };

    const variantMouseLeave = () => {
      setVariantPreviewIndex(-1);
    };

    const hasVariantPreview = variantPreviewIndex > -1;
    const currentVariant = hasVariantPreview && variants?.[variantPreviewIndex];
    const hasDensity = density === 'dense';

    const usedLink = cloneElement(link, { className: styles.link }, [
      <ProductCardTitle title={title} className={styles.heading} key={title} />,
    ]);

    const variantPreview = Array.isArray(currentVariant)
      ? currentVariant[1]
      : currentVariant;

    const showVariants = variants && variants.length > 0;
    const hasSubtitle = subTitle && subTitle.length > 0;

    const imageAttributes = {
      useSrcSet: true,
      sizes:
        imageSizes ||
        `(min-width: ${config.breakpoints.lg}px) 350px, (min-width: ${config.breakpoints.xs}px) 245px, 350px`,
    };

    return (
      <article
        className={classnames(styles.productCard, className, {
          [styles.dense]: isDense || hasDensity,
        })}
        ref={ref}
        {...other}
      >
        <div className={styles.content}>
          <div
            className={classnames(styles.text, {
              [styles.isVisible]: !showVariants,
            })}
          >
            <div className={styles.headingAndSubtitle}>
              {usedLink}
              {hasSubtitle && (
                <div
                  className={classnames(styles.brand, styles.subTitle)}
                  data-testid="productCard.subtitle"
                >
                  {subTitle?.map((item) => item).join(', ')}
                </div>
              )}
            </div>
            {additionalContent}
          </div>
          {children}
          {showVariants && (
            <ul className={classnames(styles.variants, variantsClassName)}>
              {variants?.map((variant, index) => (
                <li
                  // eslint-disable-next-line react/no-array-index-key
                  key={`variants-${index}`}
                  className={styles.singleVariant}
                  onMouseEnter={variantMouseEnter(index)}
                  onMouseLeave={variantMouseLeave}
                >
                  {Array.isArray(variant) ? variant[0] : variant}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className={styles.media}>
          <div className={styles.preview} data-testid="productCard.preview">
            <Image
              {...imageAttributes}
              altText={imageData?.altText}
              source={imageData}
            />
            {hoverImageData && (
              <Image
                {...imageAttributes}
                altText={hoverImageData?.altText}
                source={hoverImageData}
              />
            )}
            {hasVariantPreview ? variantPreview : null}
          </div>
          {labels && <div className={styles.labelsWrapper}>{labels}</div>}
        </div>
      </article>
    );
  },
);

export default ProductCard;
