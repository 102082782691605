import React, { useMemo } from 'react';
import {
  CurrencyFormat,
  PriceSizes,
} from 'molecules/PriceCompound/PriceCompound.types';
import PriceContext from 'molecules/PriceCompound/PriceCompound.context';

interface PriceCompoundProps {
  children: React.ReactNode;
  className?: string;
  /** The format options for the current currency */
  currencyFormat?: CurrencyFormat;
  /** The size of the price. "small" is a reduced version */
  size?: PriceSizes;
}

const PriceCompound = ({
  children,
  className,
  currencyFormat,
  size,
  ...other
}: PriceCompoundProps) => {
  const context = useMemo(
    () => ({
      currencyFormat,
      size,
    }),
    [currencyFormat, size],
  );

  return (
    <PriceContext.Provider value={context}>
      <div className={className} {...other}>
        {children}
      </div>
    </PriceContext.Provider>
  );
};

export default PriceCompound;
