import React from 'react';
import classNames from 'classnames';
import { getPriceParts } from 'molecules/PriceCompound/utils/priceFormat';
import { getUnitParts } from 'molecules/PriceCompound/utils/unit';
import {
  PriceCurrentProps,
  PriceSizes,
} from 'molecules/PriceCompound/PriceCompound.types';
import { usePriceContext } from 'molecules/PriceCompound/PriceCompound.context';
import FractionSuperscript from 'molecules/PriceCompound/components/FractionSuperscript';
import styles from 'molecules/PriceCompound/components/PriceCurrent/PriceCurrent.scss';

const PriceCurrent = ({
  className,
  currencyFormat: currencyFormatProp = {},
  discounted = false,
  hasPlusSign = false,
  unit,
  value,
  size: sizeProp = PriceSizes.LARGE,
  ...other
}: PriceCurrentProps) => {
  const { currencyFormat = currencyFormatProp, size = sizeProp } =
    usePriceContext();

  const { base, power } = getUnitParts(unit);
  const { integer, decimal, fraction, symbol } = getPriceParts(
    value,
    currencyFormat,
  );

  return (
    <div
      className={classNames(styles[size], className, {
        [styles.withDiscount]: !!discounted,
      })}
      {...other}
    >
      {hasPlusSign && <span className={styles.plus}>+</span>}
      {`${integer}${decimal}`}
      <FractionSuperscript
        value={fraction}
        className={styles.fraction}
        currencySymbol={symbol}
        noEmptySpaceBeforePostfix={
          currencyFormat?.custom?.noEmptySpaceBeforePostfix
        }
      />
      {unit && (
        <span className={styles.unit}>
          {` / ${base}`}
          {power && <sup>{power}</sup>}
        </span>
      )}
    </div>
  );
};

export default PriceCurrent;
