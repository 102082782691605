import { isObjectEmpty } from '@xxxlgroup/hydra-utils/common';
import {
  getCurrencySymbol,
  isSymbolBeforeThePrice,
} from 'molecules/PriceCompound/utils/symbol';
import { FRACTION_ZERO_DASH } from 'molecules/PriceCompound/PriceCompound.types';

const isPriceValueValid = (value) =>
  !(!value && value !== 0 && typeof value === 'string');

/**
 * Formatting a numeric price value into a localized price string, based on configs for Intl.NumberFormat
 */
export const intlFormatPrice = (value, currencyFormat = {}) => {
  const { locale, options } = currencyFormat;

  if (!isPriceValueValid(value) || !locale) {
    return null;
  }

  const formatter = new Intl.NumberFormat(locale, options);
  return String(formatter.format(value));
};

/**
 * Splits the price part of a formatted with Intl.NumberFormat price string
 */
export const getRawPriceParts = (price) => {
  const [integer, decimal, fraction] = price.split(/([^-0-9−])([0-9]{2})$/);

  return {
    integer,
    decimal,
    fraction,
  };
};

/**
 * Formatting a fraction string
 */
export const formatFraction = (fraction, withDash) =>
  fraction === '00' && withDash ? FRACTION_ZERO_DASH : fraction;

/**
 * Formatting an integer string for prices with a symbol before the price
 * Needed because the Intl.NumberFormat is placing the minus ('-') for negative prices
 * between the currency symbol and the numbers for prices with symbol before the price
 */
export const formatIntegerForPriceWithSymbolBefore = (integer = '') => {
  // check if the integer is not a negative value
  if (!integer.includes('-')) {
    return integer;
  }

  return `- ${integer.replace('-', ' ').trim()}`;
};

/**
 * Formatting raw prices with a postfix - currently used for SE, HR
 */
export const parsePriceWithPostfix = (price, symbol, currencyFormat = {}) => {
  if (!isPriceValueValid(price) || isObjectEmpty(currencyFormat)) {
    return {};
  }

  const {
    custom: { postfix },
  } = currencyFormat;
  const priceWithoutSymbol = price.replace(symbol, '').trim();
  const {
    integer,
    decimal = '',
    fraction = '',
  } = getRawPriceParts(priceWithoutSymbol);
  const fractionWithPostfix = `${fraction}${postfix}`;

  return {
    price: `${integer}${decimal}${fractionWithPostfix}`,
    integer,
    decimal,
    fraction: fractionWithPostfix,
    symbol: postfix.trim(),
  };
};

/**
 * Formatting Intl.NumberFormat price string with a currency symbol after the price (Exp: 12,00 zł)
 */
export const parsePriceWithSymbolAfter = (
  price,
  symbol,
  currencyFormat = {},
) => {
  if (!isPriceValueValid(price) || isObjectEmpty(currencyFormat) || !symbol) {
    return {};
  }

  const { zeroFractionWithDash, hideCurrency } = currencyFormat;
  const priceWithoutSymbol = price.replace(symbol, '').trim();
  const {
    integer,
    decimal = '',
    fraction: fractionRaw = '',
  } = getRawPriceParts(priceWithoutSymbol);

  const formattedFraction = formatFraction(fractionRaw, zeroFractionWithDash);
  const fraction = hideCurrency
    ? formattedFraction
    : `${formattedFraction} ${symbol}`;

  return {
    price: `${integer}${decimal}${fraction}`,
    integer,
    decimal,
    fraction,
    symbol,
  };
};

/**
 * Formatting Intl.NumberFormat price string with a currency symbol before the price (Exp: € 344,44)
 */
export const parsePriceWithSymbolBefore = (
  price,
  symbol,
  currencyFormat = {},
) => {
  if (!isPriceValueValid(price) || isObjectEmpty(currencyFormat) || !symbol) {
    return {};
  }

  const { integer, decimal, fraction } = getRawPriceParts(price);
  const { zeroFractionWithDash, hideCurrency } = currencyFormat;
  const formattedFraction = formatFraction(fraction, zeroFractionWithDash);

  const formattedInteger = hideCurrency
    ? integer.replace(symbol, '').trim()
    : formatIntegerForPriceWithSymbolBefore(integer);

  return {
    price: `${formattedInteger}${decimal}${formattedFraction}`,
    integer: formattedInteger,
    decimal,
    fraction: formattedFraction,
    symbol,
  };
};

/**
 * A factory, which returns price parts, based on the price type
 */
export const getPriceParts = (value, currencyFormat = {}) => {
  if (!isPriceValueValid(value) || isObjectEmpty(currencyFormat)) {
    return {};
  }

  const price = intlFormatPrice(value, currencyFormat);
  const symbol = getCurrencySymbol(
    currencyFormat.locale,
    currencyFormat.options?.currency,
  );

  // prices with postfix
  if (currencyFormat.custom?.postfix) {
    return parsePriceWithPostfix(price, symbol, currencyFormat);
  }

  return isSymbolBeforeThePrice(price, symbol)
    ? // prices with the currency symbol BEFORE the price
      parsePriceWithSymbolBefore(price, symbol, currencyFormat)
    : // prices with the currency symbol AFTER the price
      parsePriceWithSymbolAfter(price, symbol, currencyFormat);
};
