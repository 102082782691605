import React from 'react';
import Card from 'molecules/Card';
import Heading from 'components/Heading';
import Image from 'components/Image';
import Icon from 'components/Icon';
import { Image as ImageType, Glyph } from 'types/typeDefinitions';
import classNames from 'classnames';

import styles from 'molecules/ContentCard/ContentCard.scss';

const THEME_TYPE = {
  default: 'default',
  primary: 'primary',
};

interface ContentCardProps {
  /** Action elements at the bottom. F.e. a button */
  actionContent?: React.ReactNode;
  /** Content. Can be any element */
  children: React.ReactNode;
  className?: string;
  /** Svg or image. Will be displayed above the header */
  media?: string | Glyph | ImageType;
  /**
   * adjusting: adjusts the ContentCard to its parent's height
   * loose: padding 64px
   * tight: padding 32px
   * medium: padding 32px - deprecated!
   */
  spacing?: 'adjusting' | 'loose' | 'tight' | 'medium';
  /** Sets background and font-color */
  theme?: 'default' | 'primary';
  /** Title. */
  title: string;
  /** Additional classname for the title */
  titleClassName?: string;
  /** Level of the Title, H2 - H4 */
  titleLevel?: 2 | 3 | 4;
  /** Full width image displayed at the very top */
  topImage: string | ImageType;
}

const ContentCard = ({
  actionContent,
  children,
  className,
  titleClassName,
  titleLevel = 3,
  media,
  spacing = 'tight',
  title,
  theme = 'default',
  topImage,
  ...other
}: ContentCardProps) => {
  const renderMedia = () => {
    if (!media) {
      return null;
    }
    /*eslint-disable */
    return typeof media === 'string' ||
      (typeof media === 'object' && (media as ImageType).altText) ? (
      <Image
        altText={(media as ImageType).altText || title}
        source={media as ImageType}
        className={styles.image}
        data-testid="contentCard.image"
      />
    ) : (
      <Icon
        glyph={media as Glyph}
        className={styles.icon}
        data-testid="contentCard.icon"
      />
    );
  };

  return (
    <Card
      hasBorder
      className={classNames(styles.root, className, {
        [styles.alignTop]: topImage,
      })}
      theme={theme}
      {...other}
    >
      <div className={styles.contentCardWrapper}>
        {topImage && (
          <div className={styles.topImageWrapper}>
            <Image
              source={topImage}
              className={styles.topImage}
              data-testid="contentCard.imageOnTop"
            />
          </div>
        )}
        <div
          className={classNames(
            styles.contentWrapper,
            styles[`spacing-${spacing}`],
            { [styles.topImageSpacing]: topImage },
          )}
        >
          {renderMedia()}
          <div className={classNames({ [styles.topContent]: topImage })}>
            {title && (
              <Heading
                SEO={!titleLevel ? 'h3' : true}
                level={titleLevel}
                content={title}
                className={classNames(styles.heading, titleClassName, {
                  [styles[`theme-${theme}`]]: THEME_TYPE.primary,
                })}
              />
            )}
          </div>
          {children && <div className={styles.content}>{children}</div>}
          {actionContent && (
            <div className={styles.actionContent}>{actionContent}</div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default ContentCard;
