import React, { cloneElement } from 'react';
import classnames from 'classnames';

import Image from 'components/Image';
import Heading from 'components/Heading';
import { CategoryCardProps } from 'molecules/CategoryCard/CategoryCard.types';
import styles from 'molecules/CategoryCard/CategoryCard.scss';

const CategoryCard = ({
  className,
  density,
  image,
  imageSizes,
  isDense = false,
  items,
  link,
  rectangular,
  title,
  isSingleColumnAllowed = false,
  ...other
}: CategoryCardProps) => {
  const hasDensity = density === 'dense' || isDense;
  let aspectRatio: string | undefined;
  if (hasDensity || rectangular) {
    aspectRatio = '3:2';
  } else if (!rectangular) {
    aspectRatio = '1:1';
  }

  const titleLink = cloneElement(
    link,
    { className: classnames(styles.link, { [styles.noPadding]: hasDensity }) },
    [
      <Heading
        SEO
        level={3}
        content={title}
        className={styles.heading}
        key={title}
      />,
    ],
  );

  const renderItems = () =>
    items?.map((item, index) => (
      <li
        className={styles.listItem}
        // there is no stable ID for rendering items, therefore this is a last resort
        // eslint-disable-next-line react/no-array-index-key
        key={`${encodeURI(title).toLowerCase()}-${index}`}
      >
        {item}
      </li>
    ));

  return (
    <div
      className={classnames(
        styles.card,
        { [styles.isDense]: hasDensity },
        className,
      )}
      {...other}
    >
      {titleLink}
      {items && !hasDensity && (
        <ul className={styles.navigationList}>{renderItems()}</ul>
      )}
      <div
        className={classnames(styles.squareImage, {
          [styles.rectangularImage]: rectangular,
          [styles.singleColumn]: isSingleColumnAllowed,
        })}
      >
        {image && (
          <Image
            className={styles.image}
            sizes={imageSizes || undefined}
            useSrcSet
            aspectRatio={aspectRatio}
            source={image}
            width={720}
          />
        )}
      </div>
    </div>
  );
};

export default CategoryCard;
