import React from 'react';
import classNames from 'classnames';

import styles from 'molecules/PriceCompound/components/InfoPromotion/InfoPromotion.scss';

interface InfoPromotionProps {
  className?: string;
  /** Promotion info for the product */
  message: React.ReactNode;
}
const InfoPromotion = ({
  message,
  className,
  ...other
}: InfoPromotionProps) => (
  <div className={classNames(styles.promotion, className)} {...other}>
    {message}
  </div>
);

export default InfoPromotion;
