import { unitRegex } from 'utils/regex';

export const getUnitParts = (unit) => {
  if (!unit) {
    return {};
  }

  const parsedUnit = unit !== 'm²' ? unit : unit.replace('²', '2');
  const [, base = '', power = ''] = parsedUnit.split(unitRegex);
  return {
    base,
    power,
  };
};
