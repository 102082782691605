import React from 'react';
import classnames from 'classnames';
import { arrowRight } from '@xxxlgroup/hydra-icons';
import Heading from 'components/Heading';
import { CardOverlayProps } from 'molecules/Card/components/CardOverlay/CardOverlay.types';

import styles from 'molecules/Card/components/CardOverlay/CardOverlay.scss';
import { pseudoIcon } from '@xxxlgroup/hydra-utils/icon';

const TEXT_MAX_LENGTH = 75;

const CardOverlay = ({
  children,
  className,
  content,
  hasLink = false,
  SEO = false,
  variant = 'text',
}: CardOverlayProps) => {
  const setHeadingTextMaxLength = (textHeading: string) => {
    if (textHeading.length >= TEXT_MAX_LENGTH) {
      return `${textHeading.slice(0, TEXT_MAX_LENGTH)}...`;
    }
    return textHeading;
  };

  const showArrow = hasLink && children;

  const [iconStyle, iconClassName] = pseudoIcon(arrowRight);

  return (
    <div
      className={classnames(
        className,
        styles.overlay,
        styles[`variant-${variant}`],
      )}
    >
      <div className={styles.heading}>
        <Heading
          level={4}
          content={setHeadingTextMaxLength(children)}
          noMargin
          SEO={SEO}
          className={styles.text}
          data-purpose="cardoverlay-heading"
        />
        {showArrow && (
          <div
            className={classnames(styles.iconWrapper, iconClassName)}
            style={iconStyle}
          />
        )}
      </div>
      {content && <div className={styles.content}>{content}</div>}
    </div>
  );
};

export default CardOverlay;
