import React, { cloneElement, useMemo } from 'react';
import { sanitize } from 'isomorphic-dompurify';
import classnames from 'classnames';
import Heading from 'components/Heading';
import Content from 'components/Content';
import TeaserMedia from 'molecules/Teaser/components/TeaserMedia/TeaserMedia';
import { TeaserProps } from 'molecules/Teaser/Teaser.types';

import styles from 'molecules/Teaser/Teaser.scss';

const Teaser = ({
  additionalContent,
  bodyClassName,
  button,
  children,
  className,
  contactEmail,
  contactPhone,
  contentClassName,
  contentTag: Tag = 'div',
  fetchPriority,
  loading = 'lazy',
  headingSeoLevel = 'h2',
  informationalText,
  i18n,
  link,
  media,
  mediaClassName,
  /** @deprecated - please use loading instead */
  hasLazyLoadImage = true,
  subTitle,
  title,
  textTheme = 'primary',
  variant = 'dark',
  withSubtitleBackground,
  withTitleBackground,
  ...other
}: TeaserProps) => {
  const usedLink = useMemo(
    () => cloneElement(link, { className: styles.link }),
    [link],
  );

  return (
    <div className={classnames(styles.teaser, styles[variant])} {...other}>
      {!!link && usedLink}
      <div className={classnames(styles.main, className)}>
        <TeaserMedia
          loading={loading}
          hasLazyLoadImage={hasLazyLoadImage}
          fetchPriority={fetchPriority}
          className={mediaClassName}
          media={media}
          i18n={i18n}
        />
        <Tag className={classnames(styles.content, contentClassName)}>
          {children && <div className={styles.children}>{children}</div>}
          <div className={classnames(styles.body, bodyClassName)}>
            {title && (
              <Heading
                className={classnames(
                  styles.title,
                  {
                    [styles.themedTitle]: withTitleBackground,
                  },
                  withTitleBackground && styles[textTheme],
                )}
                content={
                  withTitleBackground ? (
                    <span
                      className={styles.themedContent}
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{ __html: sanitize(title) }}
                    />
                  ) : (
                    title
                  )
                }
                level={2}
                SEO={headingSeoLevel}
              />
            )}
            {subTitle && (
              <p
                className={classnames(
                  styles.subTitle,
                  {
                    [styles.themedSubTitle]: withSubtitleBackground,
                  },
                  withSubtitleBackground && styles[textTheme],
                )}
              >
                {withSubtitleBackground ? (
                  <span className={styles.themedContent}>{subTitle}</span>
                ) : (
                  subTitle
                )}
              </p>
            )}
            {additionalContent}
            {(contactEmail || contactPhone) && (
              <div className={styles.contactWrapper}>
                {contactEmail}
                {contactPhone}
              </div>
            )}
            {button && <div className={styles.button}>{button}</div>}
            {informationalText && (
              <Content
                tag="p"
                className={styles.informationalText}
                content={informationalText}
              />
            )}
          </div>
        </Tag>
      </div>
    </div>
  );
};

export default Teaser;
