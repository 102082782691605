import React from 'react';
import classnames from 'classnames';
import { config } from '@xxxlgroup/hydra-config';
import Image from 'components/Image';
import { FetchPriorityType, Image as ImageType } from 'types/typeDefinitions';
import styles from 'molecules/Card/components/CardImage/CardImage.scss';

interface CardImageProps {
  className?: string;
  /* fetch priority of the image */
  fetchPriority?: FetchPriorityType;
  /** Overwrites the default image sizes for better image quality and loading performance. */
  imageSizes?: string;
  /** Implements the image as background. */
  isBackground?: boolean;
  /** Defines when the image should start loading. */
  loading?: 'eager' | 'lazy';
  /** Path to the image file. */
  source: string | ImageType;
}

const CardImage = ({
  className,
  imageSizes = `(min-width: 991px) 1200px, (min-width: ${config.breakpoints.md}px) 800px, 100vw`,
  isBackground = false,
  fetchPriority,
  loading = 'lazy',
  source,
  ...other
}: CardImageProps) => {
  const imageAttributes = {
    ...other,
    source,
    className: classnames(className, styles.image, {
      [styles.isBackground]: isBackground,
    }),
    ...(isBackground && {
      useSrcSet: true,
      sizes: imageSizes,
    }),
    role: 'presentation',
  };

  return (
    <Image
      fetchpriority={fetchPriority}
      loading={loading}
      {...imageAttributes}
    />
  );
};

export default CardImage;
