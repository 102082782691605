// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { ComponentType, ReactNode } from 'react';

const withLinkModifier =
  (LinkComponent: ComponentType) =>
  (externalProps: object) =>
  (internalProps: object, children: ReactNode) => (
    <LinkComponent {...internalProps} {...externalProps}>
      {children}
    </LinkComponent>
  );

export default withLinkModifier;
