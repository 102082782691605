import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { isArrayEmpty, noop } from '@xxxlgroup/hydra-utils/common';
import { rawFilesToPreviews } from 'molecules/Dropzone/components/FilesPreview/utils/rawFilesToPreviews';
import File from 'molecules/Dropzone/components/File';
import {
  FileI18nType,
  PreviewType,
  MetaRawType,
} from 'molecules/Dropzone/Dropzone.types';
import styles from 'molecules/Dropzone/components/FilesPreview/FilesPreview.scss';

interface FilesPreviewProps {
  /** a callback function, which provides the file index as a parameter and should return markup with an additional action bar */
  actionBar?: (index: number) => React.ReactNode;
  className?: string;
  disabled: boolean;
  /** provides translations */
  i18n?: FileI18nType;
  /** files Array */
  files: Array<MetaRawType>;
  /** callback for file removing */
  onRemove: (event: React.MouseEvent, indexToRemove: number) => void;
  /** callback for replacing uploaded file */
  onFileReplace?: (indexToReplace: number) => void;
  /** function to open the file dialog */
  open?: () => void;
}

const FilesPreview = ({
  actionBar,
  className,
  disabled = false,
  i18n = { removeLabel: undefined, successLabel: undefined },
  files,
  onRemove = noop,
  onFileReplace = noop,
  open = noop,
}: FilesPreviewProps) => {
  const [previews, setPreviews] = useState<Array<PreviewType>>([]);

  useEffect(() => {
    let isMounted = true;
    const previewsToLoad: Array<PreviewType> = rawFilesToPreviews(files);
    Promise.all(previewsToLoad).then((results: Array<PreviewType>) => {
      isMounted && setPreviews(results);
    });
    return () => {
      isMounted = false;
    };
  }, [files]);

  if (isArrayEmpty(previews)) {
    return null;
  }

  return (
    <ul className={classnames(styles.wrapper, className)}>
      {previews.map((preview, index) => {
        const key = `${preview.name}${index}`;
        return (
          <li
            aria-live="polite"
            aria-atomic="true"
            aria-relevant="additions"
            key={key}
          >
            <File
              actionBar={actionBar}
              preview={preview}
              index={index}
              onRemove={onRemove}
              onFileReplace={onFileReplace}
              open={open}
              i18n={i18n}
              disabled={disabled}
            />
          </li>
        );
      })}
    </ul>
  );
};

export default FilesPreview;
