import React, { useMemo } from 'react';
import classNames from 'classnames';
import {
  PriceSizes,
  PriceOldProps,
} from 'molecules/PriceCompound/PriceCompound.types';
import { getPriceParts } from 'molecules/PriceCompound/utils/priceFormat';
import { usePriceContext } from 'molecules/PriceCompound/PriceCompound.context';
import styles from 'molecules/PriceCompound/components/PriceOld/PriceOld.scss';
import { renderAsterisk } from 'molecules/PriceCompound/utils/asterisk';

const PriceOld = ({
  asteriskCount = 0,
  currencyFormat: currencyFormatProp = {},
  className,
  insteadOf,
  value,
  size: sizeProp = PriceSizes.LARGE,
  strikeThrough = true,
  ...other
}: PriceOldProps) => {
  const { currencyFormat = currencyFormatProp, size = sizeProp } =
    usePriceContext();

  const oldPrice = useMemo(() => {
    // only format the price if it is a number
    // in cases where a node or string is passed, just render this
    if (typeof value !== 'number') {
      return value;
    }

    const { price } = getPriceParts(value, currencyFormat);
    return (
      <>
        {size === PriceSizes.LARGE && insteadOf && `${insteadOf} `}
        {`${price}${renderAsterisk(asteriskCount)}`}
      </>
    );
  }, [value, currencyFormat, size, insteadOf, asteriskCount]);

  const renderOldPrice = strikeThrough === true ? <s>{oldPrice}</s> : oldPrice;

  return (
    <div
      className={classNames(styles.oldPrice, styles[size], className)}
      data-purpose="product.price.old"
      {...other}
    >
      {renderOldPrice}
    </div>
  );
};

export default PriceOld;
