/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useRef, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Modal from 'molecules/Modal';
import { cloudUpload } from '@xxxlgroup/hydra-icons';
import { DropzoneProps, MetaRawType } from 'molecules/Dropzone/Dropzone.types';
import FilesPreview from 'molecules/Dropzone/components/FilesPreview';
import { isArrayEmpty, noop } from '@xxxlgroup/hydra-utils/common';
import styles from 'molecules/Dropzone/Dropzone.scss';

interface InnerDropzoneButtonProps {
  disabled: boolean;
  open: () => void;
  openFileDialog: (event: React.MouseEvent, open: () => void) => void;
  uploadText?: string;
}

const getFilenameExtension = (filename: string) =>
  filename?.indexOf('.') >= 0 ? /[^.]+$/.exec(filename)?.[0] : undefined;

// maps FileArray to MetaRawTypeArray
// is only used to preserve MetaRawType public interface and can be removed if it's not needed anymore
const mapFileToMetaRawType = (fileArray: File[]): MetaRawType[] =>
  fileArray.map((file) => ({ raw: file, meta: file }));

const InnerDropzoneButton: React.FC<InnerDropzoneButtonProps> = ({
  disabled,
  open,
  openFileDialog,
  uploadText,
}) => {
  const { getRootProps } = useDropzone({
    noDragEventsBubbling: true,
    noClick: true,
    noKeyboard: true,
  });
  return (
    <Button
      {...getRootProps({
        'data-purpose': 'dropzone.button.upload',
        role: 'button',
        disabled,
        variant: 'secondary',
        className: classNames(styles.button, {
          [styles.disabled]: disabled,
        }),
        onClick: (e) => openFileDialog(e, open),
      })}
    >
      {uploadText}
    </Button>
  );
};

const Dropzone = ({
  acceptedFileTypes,
  actionBar,
  mimeTypeLeakedExtensions,
  className,
  errorMessages = [],
  disableClick = false,
  errors = [],
  onClick = noop,
  onChange,
  onError,
  onModalClose,
  i18n,
  files = [],
  disabled = false,
  maxFileCount = 5,
  maxSize = 10 * 1024 * 1024,
  onDragOver = noop,
  ...other
}: DropzoneProps) => {
  const preparedFiles = files.map(({ raw }) => raw);

  const disabledOrNot =
    disableClick || disabled || preparedFiles.length >= maxFileCount;

  const targetFileIndex = useRef<number | null>(null);

  const onRemove = (event: React.MouseEvent, indexToRemove: number) => {
    event.stopPropagation();
    const currentFiles = preparedFiles.filter(
      (element, index) => index !== indexToRemove,
    );
    onChange(event, mapFileToMetaRawType(currentFiles));
  };

  const onFileReplace = (indexToReplace: number) => {
    targetFileIndex.current = indexToReplace;
  };

  const handleError = useCallback(
    (newError: { meta: File; code: string }) => {
      onError('error', [...errors, newError]);
    },
    [errors, onError],
  );

  const verifyFile = useCallback(
    (file: File) => {
      const { name, size, type } = file;
      if (size > maxSize) {
        handleError({
          meta: file,
          code: 'exceedsMaxFileSize',
        });
        return false;
      }

      const trimmedFileType = !acceptedFileTypes
        ?.split(',')
        .map((item) => item.trim())
        .includes(type);

      const trimmedMimeTypeLeakedExtensions = mimeTypeLeakedExtensions
        ?.split(',')
        .map((item) => item.trim())
        .includes(getFilenameExtension(name)!);

      if (
        (acceptedFileTypes && trimmedFileType) ||
        trimmedMimeTypeLeakedExtensions
      ) {
        handleError({
          meta: file,
          code: 'unsupportedFileType',
        });
        return false;
      }
      return true;
    },
    [acceptedFileTypes, handleError, maxSize, mimeTypeLeakedExtensions],
  );

  const onDrop = useCallback(
    (acceptedFiles: Array<File>) => {
      const newFiles: Array<File> = [];
      if (!isArrayEmpty(acceptedFiles)) {
        acceptedFiles.forEach((file) => {
          if (verifyFile(file)) {
            if (targetFileIndex.current !== null) {
              preparedFiles.splice(targetFileIndex.current, 1, file);
              targetFileIndex.current = null;
            } else {
              newFiles.push(file);
            }
          }
        });
      }

      onChange(
        'drop',
        mapFileToMetaRawType(
          [...preparedFiles, ...newFiles].filter((file, index) => {
            if (index + 1 <= maxFileCount) {
              return file;
            }
            handleError({
              meta: file,
              code: 'exceedsMaxFileCount',
            });
            return false;
          }),
        ),
      );
    },
    [handleError, maxFileCount, onChange, preparedFiles, verifyFile],
  );

  const closeModal = (event: React.MouseEvent | KeyboardEvent) => {
    onModalClose(event);
  };

  const renderErrors = (
    <Modal
      onClose={closeModal}
      i18n={{
        close: i18n?.close,
      }}
      heading={i18n?.headline}
      ariaLabel={i18n?.headline}
    >
      <ul className={styles.errors}>
        {errorMessages?.map((error, index) => (
          <li
            className={styles.error}
            key={`${errors[index].meta.name}-${errors[index].meta.size}`}
          >
            {error.message ?? error}
          </li>
        ))}
      </ul>
    </Modal>
  );

  const openFileDialog = (event: React.MouseEvent, open: () => void) => {
    event.stopPropagation();
    targetFileIndex.current = null;
    onClick(event);
    open();
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    disabled,
    onDrop,
    onDragOver,
    multiple: maxFileCount > 1,
    ...other,
  });

  return (
    <>
      {!isArrayEmpty(errorMessages) && renderErrors}
      <div
        {...getRootProps({
          className: classNames(className, styles.dropzone, {
            [styles.disabled]: disabledOrNot,
            [styles.dragover]: isDragActive,
          }),
          'data-purpose': 'dropzone',
        })}
      >
        <input
          {...getInputProps({
            'data-purpose': 'dropzone.input.upload',
            className: styles.input,
          })}
        />
        <div className={styles.inner}>
          <FilesPreview
            files={files}
            actionBar={actionBar}
            onRemove={onRemove}
            i18n={{
              removeLabel: i18n?.removeLabel,
              successLabel: i18n?.successLabel,
            }}
            onFileReplace={onFileReplace}
            open={open}
            disabled={disabled}
          />
          <label
            aria-label={i18n?.uploadLabel}
            className={classNames(styles.label, {
              [styles.disabled]: disabledOrNot,
            })}
            aria-disabled={disabledOrNot}
            htmlFor="fileupload"
          >
            {isArrayEmpty(preparedFiles) && (
              <Icon className={styles.icon} glyph={cloudUpload} />
            )}
            <InnerDropzoneButton
              disabled={disabledOrNot}
              openFileDialog={openFileDialog}
              open={open}
              uploadText={i18n?.uploadText}
            />
            <div className={styles.hint}>{i18n?.hint}</div>
            <div className={styles.dragoverHint}>{i18n?.dragover}</div>
          </label>
        </div>
      </div>
    </>
  );
};

export default Dropzone;
