import React, { ReactElement } from 'react';
import { FRACTION_ZERO_DASH } from 'molecules/PriceCompound/PriceCompound.types';

interface FractionSuperscriptProps {
  className?: string;
  /** The currency symbol of the price - makes sure its not the include in the superscript */
  currencySymbol: string;
  /** The fraction that should get wrapped into a superscript */
  value: number | string;
  /** a flag if the space before the currency is needed */
  noEmptySpaceBeforePostfix?: boolean;
}

const FractionSuperscript = ({
  className,
  currencySymbol = '',
  value: valueRaw = '',
  noEmptySpaceBeforePostfix = false,
}: FractionSuperscriptProps) => {
  const value = valueRaw.toString();
  const isFractionDash = value.includes(FRACTION_ZERO_DASH);

  if (currencySymbol && !value.includes(currencySymbol)) {
    const valueNew = value as unknown as ReactElement;
    return isFractionDash ? valueNew : <sup className={className}>{value}</sup>;
  }

  const parsedFraction = currencySymbol
    ? value.replace(currencySymbol, '').trim()
    : value;

  const parsedValue =
    `${parsedFraction} ${currencySymbol}` as unknown as ReactElement;

  return isFractionDash ? (
    parsedValue
  ) : (
    <>
      <sup className={className}>{parsedFraction}</sup>
      {noEmptySpaceBeforePostfix ? currencySymbol : ` ${currencySymbol}`}
    </>
  );
};

export default FractionSuperscript;
