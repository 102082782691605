import React from 'react';
import classNames from 'classnames';
import Link from 'components/Link';
import { Typography } from 'components/Link/Link.types';

import styles from 'molecules/PriceCompound/components/InfoDelivery/InfoDelivery.scss';

interface InfoDeliveryProps {
  className?: string;
  /** If the product is eligible for free delivery */
  freeDelivery?: boolean;
  /** Delivery information */
  i18n: {
    /** Delivery button text */
    deliveryCosts?: React.ReactNode;
    /** The excluding word translation */
    excluding?: React.ReactNode;
    /** Free delivery text */
    freeDelivery?: React.ReactNode;
  };
  /** Link typography. Default is 'small'. */
  linkTypography?: Typography;
  /** Callback Function when clicking on the delivery button */
  onDeliveryButtonClick?: (event: React.MouseEvent) => void;
}

const InfoDelivery = ({
  className,
  freeDelivery = false,
  i18n,
  linkTypography = 'small',
  onDeliveryButtonClick,
  ...other
}: InfoDeliveryProps) => {
  const {
    deliveryCosts: deliveryCostsMessage,
    excluding: excludingMessage,
    freeDelivery: freeDeliveryMessage,
  } = i18n;

  if (freeDelivery) {
    return (
      <span className={classNames(styles.freeDelivery, className)}>
        {freeDeliveryMessage}
      </span>
    );
  }

  if (!deliveryCostsMessage || !excludingMessage) {
    return null;
  }

  return (
    <div className={classNames(styles.delivery, className)} {...other}>
      <span>{excludingMessage} </span>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link
        className={styles.deliveryButton}
        onClick={onDeliveryButtonClick}
        theme="concrete"
        typography={linkTypography}
        underline
      >
        {deliveryCostsMessage}
      </Link>
    </div>
  );
};

export default InfoDelivery;
