/**
 * Extracting a currency symbol based on locale and currency
 */
export const getCurrencySymbol = (locale, currency) => {
  if (!currency || !locale) {
    return null;
  }

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
    .format(0)
    .replace(/\d/g, '')
    .trim();
};

/**
 * Checking if a Intl.NumberFormat price string has a symbol before the price - € 344,44
 * and its not like 12,00 zł
 */
export const isSymbolBeforeThePrice = (price, symbol) =>
  price.replace('-', '').trim().charAt(0) === symbol.charAt(0);
