import React from 'react';
import classNames from 'classnames';
import { config } from '@xxxlgroup/hydra-config';
import type {
  FetchPriorityType,
  Image as ImageTypes,
  MediaContainer,
  Video,
} from 'types/typeDefinitions';
import VideoCustom from 'components/VideoCustom';
import Image from 'components/Image';
import type { VideoCustomI18n } from 'components/VideoCustom/VideoCustom.types';
import TeaserMediaContainer from 'molecules/Teaser/components/TeaserMediaContainer';
import styles from 'molecules/Teaser/components/TeaserMedia/TeaserMedia.scss';

interface TeaserMediaProps {
  /** @ignore */
  className?: string;
  /* fetch priority of the image */
  fetchPriority?: FetchPriorityType;
  /** Provide message codes for video */
  i18n?: VideoCustomI18n;
  /** Defines when the image should start loading. */
  loading?: 'eager' | 'lazy';
  /** video or image or mediaContainer */
  media: ImageTypes | MediaContainer | Video;
  /** @deprecated - please use loading instead */
  hasLazyLoadImage?: boolean;
}

const TeaserMedia = ({
  className,
  media,
  i18n,
  hasLazyLoadImage = true,
  loading,
  fetchPriority,
}: TeaserMediaProps) => {
  switch (media?.fileType) {
    case 'IMAGE': {
      return (
        <Image
          source={media}
          useSrcSet
          sizes={`(min-width: ${config.breakpoints.xxl + 1}px) ${
            config.breakpoints.xxl
          }px, 100vw`}
          className={classNames(className, styles.image)}
          loading={loading || (hasLazyLoadImage ? 'lazy' : 'eager')}
          fetchpriority={fetchPriority}
        />
      );
    }
    case 'MEDIA_CONTAINER': {
      return (
        <TeaserMediaContainer
          mediaContainer={media as MediaContainer}
          className={classNames(className, styles.mediaContainer)}
          loading={loading || (hasLazyLoadImage ? 'lazy' : 'eager')}
          fetchPriority={fetchPriority}
        />
      );
    }
    case 'VIDEO': {
      return (
        <VideoCustom
          autoplay
          className={{ video: styles.video, player: styles.videoPlayer }}
          hideControls
          i18n={i18n}
          loop
          muted
          poster={media}
          source={media}
        />
      );
    }
    default:
      return null;
  }
};

export default TeaserMedia;
