import React, {
  ReactPortal,
  useEffect,
  useState,
  useCallback,
  ReactNode,
  useRef,
  forwardRef,
  ForwardedRef,
  useId,
} from 'react';
import { createPortal } from 'react-dom';
import classnames from 'classnames';
import FocusLock from 'react-focus-lock';
import Heading from 'components/Heading';
import Backdrop from 'components/Backdrop';
import Button from 'components/Button';
import { chevronLeft, x } from '@xxxlgroup/hydra-icons';
import { disableScrolling, enableScrolling } from 'utils/common';
import { noop } from '@xxxlgroup/hydra-utils/common';
import { ModalProps } from 'molecules/Modal/Modal.types';
import { session } from '@xxxlgroup/hydra-utils/storage';

import styles from 'molecules/Modal/Modal.scss';

const getPortalRoot = (portalRoot?: HTMLElement | null) => {
  if (portalRoot) {
    return portalRoot;
  }

  const defaultPortalRoot = document.getElementById('modal');

  if (!defaultPortalRoot) {
    throw new Error(
      'There has to be a DOM-node with id="modal" in order to use the Modal-Component.',
    );
  }

  return defaultPortalRoot;
};

const renderHeading = (heading: ReactNode, id?: string, className?: string) => {
  if (!heading) {
    return null;
  }

  return (
    <Heading
      anchorId={id}
      data-purpose="modal.heading"
      SEO
      level={3}
      className={classnames(styles.heading, className)}
    >
      {heading}
    </Heading>
  );
};

const disableBodyScrolling = (scrollBarWidth: number) => {
  disableScrolling();
  document.body.style.paddingRight = `${scrollBarWidth}px`;
};

const enableBodyScrolling = (preserveScrollPosition: boolean) => {
  document.body.style.paddingRight = '0';
  enableScrolling(preserveScrollPosition);
};

const renderCloseButton = (
  closeButtonHasInitialFocus: boolean,
  onClose?: (event: KeyboardEvent | React.MouseEvent) => void,
  label?: string,
  iconType?: 'back' | 'close',
  className?: string,
) => {
  if (onClose) {
    return (
      <Button
        ariaLabel={label}
        className={classnames(styles.close, className)}
        data-purpose="modal.closeButton"
        glyphAfter={iconType === 'back' ? chevronLeft : x}
        onClick={onClose}
        autoFocus={closeButtonHasInitialFocus}
      />
    );
  }

  return null;
};

const isFullscreen = (variant: string, fullscreen: ReactNode) =>
  variant === 'fullscreen' && fullscreen;

const Modal = forwardRef(
  (
    props: ModalProps,
    ref: ForwardedRef<HTMLDivElement>,
  ): ReactPortal | null => {
    const {
      ariaLabel = 'dialog',
      ariaDescribedBy,
      children,
      className,
      closeButtonHasInitialFocus = true,
      closeOnBackDropClick = true,
      closeIconType = 'close',
      fullscreen = null,
      hasFocusLock = true,
      hasBackdropOnMobile = false,
      heading = null,
      i18n = { close: 'close' },
      onClose = noop,
      portalRoot = null,
      preserveScrollPosition = true,
      stickyHeader = true,
      variant = 'default',
      wrapperClassName,
    } = props;

    const classNameString = typeof className === 'string' ? className : null;
    const classNameObject = typeof className === 'object' ? className : null;

    const [scrolling, setScrolling] = useState(false);
    const originalActiveElement = useRef<Element>(
      // eslint-disable-next-line ssr-friendly/no-dom-globals-in-react-fc
      typeof window === 'undefined' ? null : document.activeElement,
    );

    useEffect(
      () => () => {
        setTimeout(() => {
          // Timout needed because all links and buttons have a negative tabIndex while modal is open
          // eslint-disable-next-line react-hooks/exhaustive-deps
          (originalActiveElement?.current as HTMLElement)?.focus();
        }, 50);
      },
      [],
    );

    const onKeyDown = useCallback(
      (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          onClose(event);
        }
      },
      [onClose],
    );

    useEffect(() => {
      if (onClose === null) {
        return undefined;
      }

      document.addEventListener('keydown', onKeyDown, false);

      return () => {
        document.removeEventListener('keydown', onKeyDown, false);
        return undefined;
      };
    }, [onClose, onKeyDown]);

    useEffect(() => {
      // since the scrollbars can be adapted via OS/browser settings
      // session storage is used instead of local storage
      if (!session.getItem('scrollBarWidth')) {
        const scrollBarWidth =
          window.innerWidth - document.documentElement.clientWidth;
        session.setItem('scrollBarWidth', scrollBarWidth);
      }
    }, []);

    useEffect(() => {
      const scrollBarWidth = session.getItem('scrollBarWidth');
      const isScrollBarWidthGreaterThanZero = scrollBarWidth > 0;

      // only call the function, if there is not an overlay scrollbar
      // to avoid setting unnecessary rules and reflows

      if (isScrollBarWidthGreaterThanZero) {
        disableBodyScrolling(scrollBarWidth);
      } else {
        disableScrolling();
      }
      return () => {
        if (isScrollBarWidthGreaterThanZero) {
          enableBodyScrolling(preserveScrollPosition);
        } else {
          enableScrolling(preserveScrollPosition);
        }
      };
    }, [preserveScrollPosition]);

    useEffect(() => {
      getPortalRoot();
    });

    const handleScroll = useCallback(
      (event: React.UIEvent) => {
        const target = event.target as HTMLElement;
        if (!!target.scrollTop === scrolling) {
          setScrolling(!!target.scrollTop);
        }
      },
      [scrolling],
    );

    const ariaId = useId();

    if (typeof window === 'undefined') {
      return null;
    }

    const renderBody = (className?: string) => (
      <>
        <section
          className={classnames(styles.body, className, {
            [styles.stickyHeader]: stickyHeader,
          })}
          data-purpose="modal.body"
          onScroll={handleScroll}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={stickyHeader ? 0 : -1}
          aria-describedby={ariaDescribedBy}
        >
          {children}
        </section>
        {isFullscreen(variant, fullscreen) && (
          <div
            data-purpose="modal.fullscreen"
            className={styles.fullscreenContent}
          >
            {fullscreen}
          </div>
        )}
      </>
    );

    const renderContent = () => {
      const classNamesWrapper = classnames(
        wrapperClassName,
        classNameObject?.wrapper,
        styles.wrapper,
        {
          [styles.overlayWrapper]: variant === 'overlay',
        },
      );

      const classNamesModal = classnames(
        styles.defaultVariant,
        classNameString,
        classNameObject?.modal,
        {
          [styles.stickyHeader]: !stickyHeader,
          [styles.fullscreenVariant]: isFullscreen(variant, fullscreen),
          [styles.sidebarVariant]: variant === 'sidebar',
          [styles.sidebarLargeVariant]: variant === 'sidebar-large',
          [styles.sidebarLeftVariant]: variant === 'sidebar-left',
          [styles.fitVariant]: variant === 'fit',
          [styles.overlayVariant]: variant === 'overlay',
          [styles.hasBackdropOnMobile]: hasBackdropOnMobile,
        },
      );

      return (
        <div className={classNamesWrapper} role="dialog" aria-label={ariaLabel}>
          <Backdrop onClick={closeOnBackDropClick ? onClose : noop} />
          <div
            className={classNamesModal}
            data-purpose={`modal.${variant}.wrapper`}
            ref={ref}
          >
            <div
              className={classnames(styles.head, classNameObject?.head, {
                [styles.scrolling]: scrolling,
              })}
            >
              {renderHeading(heading, ariaId, classNameObject?.heading)}
              {renderCloseButton(
                closeButtonHasInitialFocus,
                onClose,
                i18n?.close,
                closeIconType,
                classNameObject?.close,
              )}
            </div>
            {renderBody(classNameObject?.body)}
          </div>
        </div>
      );
    };

    return createPortal(
      hasFocusLock ? <FocusLock>{renderContent()}</FocusLock> : renderContent(),
      getPortalRoot(portalRoot),
    ) as ReactPortal;
  },
);

export default Modal;
