import React from 'react';
import classNames from 'classnames';

import styles from 'molecules/PriceCompound/components/InfoSavings/InfoSavings.scss';

interface InfoSavingsProps {
  className?: string;
  /** Discount info for the product */
  message: React.ReactNode;
}
const InfoSavings = ({ message, className, ...other }: InfoSavingsProps) => (
  <div className={classNames(styles.discount, className)} {...other}>
    {message}
  </div>
);

export default InfoSavings;
