import { createContext, useContext } from 'react';
import {
  CurrencyFormat,
  PriceSizes,
} from 'molecules/PriceCompound/PriceCompound.types';

const PriceContext = createContext<{
  currencyFormat?: CurrencyFormat;
  size?: PriceSizes;
}>({});

export const usePriceContext = () => useContext(PriceContext);

export default PriceContext;
