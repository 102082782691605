import React from 'react';
import classNames from 'classnames';

import styles from 'molecules/PriceCompound/components/InfoVAT/InfoVAT.scss';

interface InfoVATProps {
  className?: string;
  /** Information text regarding VAT info */
  message: React.ReactNode;
}
const InfoVAT = ({ className, message, ...other }: InfoVATProps) => (
  <div className={classNames(styles.vatInfo, className)} {...other}>
    {message}
  </div>
);

export default InfoVAT;
