import React from 'react';
import { FetchPriorityType, MediaContainer } from 'types/typeDefinitions';
import { config } from '@xxxlgroup/hydra-config';
import Image from 'components/Image';
import buildSrcSet from 'components/Image/utils/buildSrcSet';
import styles from 'molecules/Teaser/components/TeaserMediaContainer/TeaserMediaContainer.scss';

interface TeaserMediaContainerProps {
  className?: string;
  /* fetch priority of the image */
  fetchPriority?: FetchPriorityType;
  loading?: 'lazy' | 'eager';
  mediaContainer: MediaContainer;
}

const TeaserMediaContainer = ({
  className,
  loading,
  mediaContainer,
  fetchPriority,
}: TeaserMediaContainerProps) => {
  const mobileViewBreak = config.breakpoints.sm;
  const mobileImage = mediaContainer.mobile;
  const desktopImage = mediaContainer.desktop;

  const mobileSrcSet = buildSrcSet(mobileImage);
  const desktopSrcSet = buildSrcSet(desktopImage);

  if (!mobileImage || !desktopImage) {
    return null;
  }

  return (
    <picture className={className} data-testid="mediaContainerPicture">
      <source
        srcSet={mobileSrcSet}
        media={`(max-width: ${mobileViewBreak - 1}px)`}
        data-testid="mobileSrcSet"
      />
      <source
        srcSet={desktopSrcSet}
        media={`(min-width: ${mobileViewBreak}px)`}
        data-testid="desktopSrcSet"
      />
      <Image
        className={styles.image}
        loading={loading}
        fetchpriority={fetchPriority}
        useSrcSet
        source={mobileImage}
        sizes={`(min-width: ${config.breakpoints.xxl + 1}px) ${
          config.breakpoints.xxl
        }px, 100vw`}
      />
    </picture>
  );
};

export default TeaserMediaContainer;
